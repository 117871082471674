var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tool-content"},[(_vm.error)?_c('div',{staticClass:"p-3"},[_vm._v(_vm._s(_vm.error))]):_c('DxDataGrid',{key:_vm.renderToggle,staticClass:"mainContent content-block",attrs:{"data-source":_vm.transactions,"columns":_vm.dxColumns,"no-data-text":_vm.notFoundText,"remote-operations":_vm.remoteTransactionOperations,"on-row-click":_vm._expandRow,"master-detail":{template: 'transactionMasterDetail'}},on:{"cell-prepared":_vm.onCellPrepared},scopedSlots:_vm._u([{key:"transactionMasterDetail",fn:function(ref){
var selectedTransaction = ref.data;
return [_c('div',{staticClass:"transactionDetails"},[_c('TransactionHistory',{attrs:{"transaction-id":selectedTransaction.data.id}}),_c('TransactionDocumentSyncDetails',{attrs:{"transaction-id":selectedTransaction.data.id}})],1)]}},{key:"statusIconTemplate",fn:function(ref){
var row = ref.data;
return [_c('StatusIcon',{attrs:{"initials":_vm.getInitials(row.data.statusString),"css-class":_vm._getStatusBgCssClass(row.data.status),"synced":row.data.synced}})]}},{key:"syncStateTemplate",fn:function(ref){
var row = ref.data;
return [_c('SyncStatusBadge',{attrs:{"id":row.data.id,"is-synced":row.data.synced,"since":row.data.syncStateSince}})]}},{key:"requestedTimePeriodTemplate",fn:function(ref){
var row = ref.data;
return [_c('TimePeriodElement',{attrs:{"period":row.data.requested}})]}},{key:"planedTimePeriodTemplate",fn:function(ref){
var row = ref.data;
return [_c('TimePeriodElement',{attrs:{"period":row.data.planed}})]}},{key:"avalIdTemplate",fn:function(ref){
var row = ref.data;
return [_c('RouterLink',{attrs:{"to":{ name: 'agreementDetails', params: { id: row.data.avalId}}}},[_vm._v(" "+_vm._s(row.data.avalId)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
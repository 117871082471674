






















































































































import {Component, Vue} from "vue-property-decorator";
import MatchingStatusBar from "@/components/matching/MatchingStatusBar.vue";
import {ViewLabel} from "@/store/types";
import {FLUENT_ICON_AGREEMENT_DETAILS, FLUENT_ICON_REJECT, STATE_AGREEMENT_DETAILS} from "@/types/StateConst";
import {Matching, StatusEnum} from "@/types/Matching";
import MatchingConnector from "@/components/matching/MatchingConnector";
import DxButton from "devextreme-vue/button";
import DxLoadPanel from "devextreme-vue/load-panel";
import {Agreement} from "@/types/Agreement";
import AgreementConnector from "@/components/matching/AgreementConnector";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import {getDateString} from "@/util/string.module";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {AGREEMENT_DETAILS_ROUTE_NAME} from "@/router";
import {CHANGE_BREADCRUMB} from "@/types/MutationConst";
import AgreementContext from "@/components/frame/AgreementContext.vue";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {documentTypeMappingDSO} from "@/components/documentTypeMapping/DocumentTypeMappingSource";
import {DxTagBox} from "devextreme-vue";
import {documentTypeDSO} from "@/components/documentTypeMapping/DocumentTypeSource";
import notify from "devextreme/ui/notify";

@Component({
    components: {
        AgreementContext,
        MatchingStatusBar,
        DxButton,
        DxLoadPanel,
        DxTagBox,
    },
    methods: {
        getDateString: getDateString,
    },
})
export default class AgreementDetails extends Vue {

    public static readonly BASIC_VIEW_LABEL: Readonly<ViewLabel> = {
        primary: "Anfrage Detail",
        secondary: false,
        icon: FLUENT_ICON_AGREEMENT_DETAILS,
        cssClass: "",
    };
    iconReject: string = FLUENT_ICON_REJECT;
    matching: Matching | null = null;
    agreement: Agreement | null = null;
    error: any = null;
    loadingPosition = {of: '#agreementDetailsView'};
    loading: boolean = true;
    loadingMessage: string = "Lade ...";
    processingPosition = {of: '#buttonArea'};
    processing: boolean = false;
    processingMessage: string = "Wird verarbeitet ...";
    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_AGREEMENT_DETAILS,
        false,
        { name: AGREEMENT_DETAILS_ROUTE_NAME },
        false
    );

    documentTypeSource = documentTypeDSO;
    documentTypeMappingSource = documentTypeMappingDSO;

    mounted(): void {
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);

        this.$store.commit("updateViewLabel", AgreementDetails.BASIC_VIEW_LABEL);

        if (this.$store.state.matching) {
            this.handleMatchingIsLoaded();
        } else {
            this.loadMatching(this.$route.params.id);
        }

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh)
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh)
    }

    handleRefresh(): void {
        this.$store.commit("clearMatching");
        this.matching = null;
        this.agreement = null;
        this.error = null;
        this.loadMatching(this.$route.params.id);
    }

    loadMatching(id: string): void {
        this.loading = true;
        MatchingConnector.getInstance().loadById(id).then(
            matching => {
                this.$store.commit("selectMatching", matching);
                this.handleMatchingIsLoaded();
            },
            error => {
                defaultFetchErrorHandler(error);
                this.$store.commit("updateViewLabel", AgreementDetails.BASIC_VIEW_LABEL);
                this.error = error;
                this.loading = false;
                this.processing = false;
            }
        );
    }

    handleMatchingIsLoaded(): void {
        this.matching = this.$store.state.matching as Matching;
        this.$store.commit("setMatchingContextVisibility", true);
        if (this.matching.isSupplier && this.matching.status === StatusEnum.new) {
            this.$router.push({
                name: 'agreementSelection',
                params: {
                    id: this.matching.id,
                },
            });
        }

        let suffix: string = " (AG)";
        if (this.matching.isSupplier) {
            suffix = " (AN)";
        }
        const viewLabel: ViewLabel = {
            primary: AgreementDetails.BASIC_VIEW_LABEL.primary + suffix,
            icon: AgreementDetails.BASIC_VIEW_LABEL.icon,
            cssClass: this.getCssClass(),
            secondary: this.matching.id
        };
        this.$store.commit("updateViewLabel", viewLabel);
        this.loadMatchedAgreement();
    }

    abortButtonClick(matching: Matching): void {
        this.processing = true;
        MatchingConnector.cancelMatching(matching)
            .then(() => this.loadMatching(matching.id))
            .catch(
                error => {
                    defaultFetchErrorHandler(error);
                    this.error = error;
                }
            );
    }

    getCssClass(): string {
        if (this.matching) {
            return this.matching.getStatusClass() + "Agreement"
        }
        return "";
    }

    private loadMatchedAgreement(): void {
        // reset agreement, to handle loading after unlink action or loading errors
        this.agreement = null;
        if (this.matching) {
            AgreementConnector.getInstance().loadFromHref(this.matching.matchedAgreementLink).then(
                agreement => {
                    this.agreement = agreement;
                    this.loading = false;
                    this.processing = false;
                },
                error => {
                    if (error.httpStatus !== 404) {
                        defaultFetchErrorHandler(error);
                        this.error = error;
                    }
                    this.loading = false;
                    this.processing = false;
                }
            );
        }
    }

    valueChanged(e: any): void {
        if (this.matching) {
            this.matching.requiredDocuments = e.value;
            MatchingConnector.updateRequiredDocuments(this.matching, e.value)
                .catch(reason => this.error=reason)
                .then(() => {if (!this.error) {notify("gespeichert")}});
        }
    }

}


export {OAuth2Authentication};

class OAuth2Authentication{

    accessTokenURI: string;
    refreshTokenUri: string;
    clientId: string;
    clientSecret: string;
    grantType: string;
    basicAuthUsername: string;
    basicAuthPassword: string;
    scope: string | undefined;


    constructor(accessTokenURI: string, refreshTokenUri: string, clientId: string, clientSecret: string, grantType: string, basicAuthUsername: string, basicAuthPassword: string, scope?: string) {
        this.accessTokenURI = accessTokenURI;
        this.refreshTokenUri = refreshTokenUri;
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.grantType = grantType;
        this.basicAuthUsername = basicAuthUsername;
        this.basicAuthPassword = basicAuthPassword;
        this.scope = scope;
    }

    static fromJSON(json: OAuth2AuthenticationJSON){
       return new OAuth2Authentication(json.accessTokenURI,
            json.refreshTokenUri,
            json.clientId,
            json.clientSecret,
            json.grantType,
            json.basicAuthUsername,
            json.basicAuthPassword,
            json.scope);
    }

}
interface OAuth2AuthenticationJSON {
    accessTokenURI: string;
    refreshTokenUri: string;
    clientId: string;
    clientSecret: string;
    grantType: string;
    basicAuthUsername: string;
    basicAuthPassword: string;
    scope: string;
}










import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
    },
    props: {
        initials: {
            type: String,
            default: "??",
        },
        cssClass: {
            type: String,
            default: "",
        },
        filled: {
            type: Boolean,
            default: true,
        },
        synced: {
            type: Boolean,
            default: false,
        },
        hideSyncedDot: {
            type: Boolean,
            default: false,
        },
    },
})
export default class StatusIcon extends Vue {

    syncedCssClass(): string {
        if(this.$props.synced) {
            return "syncDone";
        }
        return "syncPending";
    }

}


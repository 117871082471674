import {HalTransaction} from "@/types/Transaction/HalTransaction";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {GenericEntityConnector, PropertyMapping} from "@gipa/devextreme-to-spring";

export default class TransactionConnector {
    static propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>([
        ["status", "state"],
        ["synced", "lastSync"],
        ["fulfillment", "fulfillmentTimestamp"]
    ]));

    static connector: GenericEntityConnector<HalTransaction> = new GenericEntityConnector<HalTransaction>(
        TransactionConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "transactions",
        HalTransaction,
        "lastUpdateAt",
        true
    );

    public static getInstance(): GenericEntityConnector<TransactionConnector> {
        return TransactionConnector.connector;
    }

}

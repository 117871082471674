






































































































import {Component, Vue} from "vue-property-decorator";
import {HistoricTransactionDiff} from "@/types/Transaction/HistoricTransactionDiff";
import SingleDiffElement from "@/components/transaction/SingleDiffElement.vue";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getDateString, getDateTimeString, getInitials, getTimeString} from "@/util/string.module";
import {AdditionalContent} from "@/types/AdditionalContent";

@Component({
    components: {
        StatusIcon,
        SingleDiffElement,
    },
    props: {
        diffEntry: {
            type: HistoricTransactionDiff,
            required: true,
        },
        isInitialChange: {
            type: Boolean,
            default: false,
        },
        isLatestChange: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getInitials: getInitials,
        getDateString: getDateString,
        getTimeString: getTimeString,
        getDateTimeString: getDateTimeString,
    },
})
export default class DiffEntry extends Vue {

    _propertyToString(prop: AdditionalContent): string {
        return prop.type + ": " + prop.content;
    }

    _getStatusCssClass(status: number): string {
        return "transactionStatus" + String(status);
    }

    _getStatusBgCssClass(status: number): string {
        return "backTransactionStatus" + String(status);
    }

}






























import {Component, Vue} from "vue-property-decorator";
import DxTooltip from "devextreme-vue/tooltip";
import {getDateString, getDateTimeString, getTimeString} from "@/util/string.module";

@Component({
    components: {
        DxTooltip,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        isSynced: {
            type: Boolean,
            required: true,
        },
        since: {
            type: Date,
        },
    },
})
export default class SyncStatusBadge extends Vue {

    tooltip: boolean = false;

    toggleTooltip(): void {
        this.tooltip = !this.tooltip;
    }

    getSinceDateString(): string {
        if (this.$props.since) {
            return getDateString(this.$props.since);
        }
        return "";
    }

    getSinceTimeString(): string {
        if (this.$props.since) {
            return getTimeString(this.$props.since);
        }
        return "";
    }

    getSinceDateTimeString(): string {
        return getDateTimeString(this.$props.since);
    }

}

